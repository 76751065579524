<template>
	<div class="wrc" style="padding: 20px 0">
		<div class="title_header_body">
			<div class="title_header flex">
				<div class="video_tabs pointer_s">
					培训视频
				</div>
			</div>
		</div>
		<div class="wrc-content flex-wrap">
			<div class="item flex-direction-column" v-for="(item, index) in list.data" :key="item.id"
					 @click="itemClick(item)">
				<img class="pic" :src="item.image"/>
				<div class="mask flex-center" v-if="!videoIndex">
					<img src="@/assets/image/icon/11.png"/>
				</div>
				<span class="one-line">{{ item.name }}</span>
				<div class="desc flex">
					<img src="@/assets/image/icon/10.png"/>
					<span>{{ item.create_time }}</span>
				</div>
				<!--				<div class="line" v-if="(index + 1) % 3 == 0 && list.length > 3 && index != 5"></div>-->
			</div>
		</div>
		<Lack
			v-if="list.data?.length == 0"
			text="暂无内容"
			minHeight="260px"
			imgWidth="102px"
			imgHeight="68px"
			:imgSrc="require('@/assets/image/img/15.png')"
		></Lack>
		<div class="pagination">
			<el-pagination
				@current-change="currentChange"
				background
				layout="prev, pager, next"
				:page-size="list.per_page"
				:total="list.total"
			>
			</el-pagination>
		</div>

		<el-dialog
			:title="videoUrl.name"
			:visible.sync="dialogVisible"
			width="800px"
			:append-to-body="true">
			<!--			:poster="videoUrl.image"-->
			<video v-if="dialogVisible" style="width: 760px" :src="videoUrl.url" loop controls></video>
		</el-dialog>
	</div>
</template>

<script>
import Lack from '@/components/lack/lack.vue'
import {trainVideo} from '@/service/api/index'

export default {
	components: {Lack},
	data() {
		return {
			videoUrl: {
				url: '',
				image: ''
			},
			dialogVisible: false,
			videoList: [
				{
					name: '视频',
					id: 1,
					choose: true,
				}, {
					name: '图片',
					id: 2,
					choose: false,
				}
			],
			videoIndex: 0,
			list: {
				data: []
			}
		}
	},

	mounted() {
		this.postVideoList(1)
	},

	methods: {
		videoClick(index) {
			if (this.videoList[index].choose) return
			this.videoIndex = index
			this.videoList.forEach(item => {
				item.choose = false
			})
			this.videoList[index].choose = true
			this.postVideoList(1)
		},
		postVideoList(page) {
			trainVideo({page}).then((res) => {
				this.list = Object.freeze(res.msg)
			})
		},
		itemClick(item) {
			this.dialogVisible = true
			this.videoUrl = item
			// this.$router.replace({
			// 	name: 'vdetail',
			// 	params: {
			// 		info: item
			// 	}
			// })
		},
		currentChange(page) {
			this.postVideoList(page)
		}
	}
}
</script>

<style lang="less" scoped>
.title_header_body {
	padding: 0 30px;

	.title_header {
		border-bottom: 5px solid #212F82;
		//padding: 0 30px;
		.video_tabs {
			//width: 108px;
			font-weight: 500;
			//font-size: 20px;
			color: #212F82;
			display: flex;
			font-size: 22px;
			margin-bottom: 20px;
			justify-content: center;
			align-items: center;
		}
	}
}


.wrc {
	background: #fff;
	padding: 30px 0 45px 0;

	.title-vertical {
		width: 1297px;
	}

	.wrc-content {
		margin: 0px 24px 15px 30px;
		.item {
			position: relative;
			width: 326px;
			margin-right: 30px;
			//padding-bottom: 30px;
			margin-top: 30px;
			cursor: pointer;
			transition: all 0.5s;

			&:hover {
				transform: translateY(-10px);
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
			.pic {
				width: 100%;
				height: 192px;
				border-radius: 5px;
				object-fit: cover;
			}

			.mask {
				height: 192px;
				border-radius: 5px;
				position: absolute;
				background: rgba(0, 0, 0, 0.39);
				top: 0;
				left: 0;
				right: 0;

				img {
					width: 60px;
					height: 60px;
				}
			}

			span {
				width: 100%;
				height: 22px;
				font-size: 16px;
				font-weight: bold;
				line-height: 22px;
				color: #000000;
				margin-top: 15px;
			}

			.desc {
				margin-top: 6px;

				img {
					width: 14px;
					height: 14px;
					margin-right: 5px;
				}

				span {
					height: 20px;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					color: #999999;
					margin: 0;
				}
			}

			.line {
				position: absolute;
				bottom: 0;
				// left: 0;
				right: 0;
				width: 1278px;
				height: 1px;
				background-color: #eee;
			}
		}
	}
}

/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #212F82;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 29px;
}
</style>
